//=require vendors/jQuery.js

//=require vendors/OwlCarousel.js

//=require vendors/WayPoint.js

//=require vendors/parallax.js

$(document).ready(function(){
    if($(".slider-section .slider .item").length > 1){
        $(".slider-section .slider .owl-carousel").owlCarousel({
            items: 1,
            nav: false,
            navText: ["",""],
            loop: true,
            autoplay:true,
            autoplayTimeout:3000,
            autoplayHoverPause:true,
            responsive: {
                0 : {
                    dots: false
                },
                768 : {
                    dots: true
                }
            }
        })
    }

    $(".partenaires .slider").owlCarousel({
        items: 1,
        loop: true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        responsive: {
            0 : {
                dots: false,
                nav: true
            },
            768 : {
                dots: true,
                nav: false
            }
        }
    })

    $(document).on("click", "#nav .btn-menu", function(e){
        if(!$("#nav .menu").hasClass("open")){
            $("#nav .btn-menu").addClass("open")
            $("#nav .menu").addClass("open");
            $("html").addClass("noscroll");
        } else {
            $("#nav .btn-menu").removeClass("open")
            $("#nav .menu").removeClass("open")
            $("html").removeClass("noscroll");
        }
    });

    $(document).on("click", "#nav .logo .lang li.active", function(e){
        e.preventDefault();
        if($(this).parents(".lang").hasClass("open")){
            $(this).parents(".lang").removeClass("open")
        } else {
            $(this).parents(".lang").addClass("open");
        }
        return false
    });

    $(document).on("click", function(e){
        /*if($("#nav").hasClass("open")){
            if($(e.target).parents(".content-menu").length == 0 && !$(e.target).hasClass("content-menu") && $(e.target).parents(".lang").length == 0){
                $("html").removeClass("noscroll");
                $("#nav").removeClass("open");
            }
        }*/

        if($("#nav .logo .lang").hasClass("open")){
            if($(e.target).parents(".lang").length == 0){
                $("#nav .logo .lang").removeClass("open");
            }
        }
    });

    if(window.navigator.platform.indexOf("Win")!= -1){
        $("body.home .header .text h1").addClass("is_win")
    }

    if(window.navigator.platform.indexOf("Win")!= -1){
        $("body").addClass("is_win")
    }

    if(window.navigator.platform.indexOf("Win") == -1 && window.navigator.appVersion.indexOf("Chrome") == -1 &&  window.navigator.appVersion.indexOf("Safari") != -1){
        $("body").addClass("safari")
    }

    if(window.navigator.platform.indexOf("iPhone")!= -1){
        $("body").addClass("is_iPhone")
    }

    if($(".best-packages-section").length > 0){
        /*if($(".best-packages-section .item.item-image").length > 3){
            $(".best-packages-section .row-image-slider").addClass("owl-carousel").owlCarousel({
                items: 3,
                nav: false,
                dots: false,
            })
        }*/

        
        $(document).on("click", ".packages-also-like-section .item > a", function (e) {  
            if($(window).innerWidth() > 767){
                e.preventDefault();
                return false;
            }
        })
        

        $(document).on("click", ".best-packages-section .item.item-image .image", function(){
            var index = $(this).parents(".item.item-image").index();
            if($(this).parents(".item.item-image").hasClass("active")){
                if($(window).innerWidth() < 768){
                    $(this).parents(".item.item-image").removeClass("active")
                    $(".item.item-text").eq(index).removeClass("active")
                }
            } else {
                $(this).parents(".item.item-image").siblings().removeClass("active")
                $(".item.item-text").eq(index).siblings().removeClass("active")
                $(this).parents(".item.item-image").addClass("active")
                $(".item.item-text").eq(index).addClass("active")
                if($(window).innerWidth() < 768){
                    $("html,body").animate({ 
                        scrollTop: $(".best-packages-section .item.item-image.active").offset().top - ($("#nav").innerHeight() + 25)},500)
                }
            }
        });
    }

    if($("#single_storie").length > 0){
        var title = $("#single_storie .title h2").text();
        $("#single_storie .title h2").html(title.replace(title.split(' ')[2], '<br />'+title.split(' ')[2]))
    }
});